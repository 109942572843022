import React from 'react';

const ImgCol = ({ assets, feature }) => (
  <div
  >
    <img
      src={assets.filter(asset => asset.title === feature.imageTitle)[0].file.url}
      alt={feature.imageTitle}
      className="w-100"
    />
  </div>
);

const TxtCol = ({ feature }) => (
  <div className="h-100 w-100 flex-center">
    <div className="w-75 d-flex flex-column">
      <h2>{feature.featureTitle}</h2>
      <p className="text-muted">{feature.featureContent}</p>
    </div>
  </div>
);

export const ProductFeatures = ({
  title,
  subtitle,
  features,
  assets
}) => {
  return (
    <React.Fragment>
      <section className="w-100 bg-white">
        <div className="container">
          <div className="row my-5">
            <div className="col-md-3">
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column text-center">
                <h2 className="">{title}</h2>
                <p className="text-muted">{subtitle}</p>
              </div>
            </div>
            <div className="col-md-3">
            </div>
          </div>
        </div>
      </section>
      { features.map((feature, i) => (
          <section
            key={i}
            style={{
              backgroundColor: i % 2 === 0 ? 'white' : '#F5F5F5'
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  { i % 2 === 0 ?
                    <div className="mt-5">
                      <TxtCol
                        feature={feature}
                      />
                    </div>: 
                    <ImgCol
                      assets={assets}
                      feature={feature}
                    />
                  }
                </div>
                <div className="col-md-6">
                  { i % 2 !== 0 ?
                    <TxtCol
                      feature={feature}
                    /> : 
                    <ImgCol
                      assets={assets}
                      feature={feature}
                    />
                  }
                </div>
              </div>
            </div>
          </section>
      ))}
    </React.Fragment>
  );
};

export default ProductFeatures;
