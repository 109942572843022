import React, { useState } from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  AllProducts,
  RequestContactEndpoint
} from '@constants/app';

export const ContactSection = ({ defaultProduct, bgClass }) => {

  const [ fullName, setFullName ] = useState('');
  const [ workEmail, setWorkEmail ] = useState('');
  const [ contactNumber, setContactNumber ] = useState('');
  const [ companyName, setCompanyName ] = useState('');
  const [ companyType, setCompanyType ] = useState('');
  const [ productInterest, setProductInterest ] = useState(defaultProduct); 
  const [ message, setMessage ] = useState('');
  const [ subscribeMarketing, setSubscribeMarketing ] = useState(false);

  const [ visibleDropdown, setVisibleDropdown ] = useState(false);

  const toggle = () => setVisibleDropdown(!visibleDropdown);

  return (
    <section className={classnames('w-100', bgClass || 'bg-secondary')} style={{ height: '100vh' }}>
      <div className="h-100 flex-center">
        <div className="container p-5">
          <div className="row">
            <div className="col-12">
              <div className="flex-center flex-column">
                <h2>
                  Submit an enquiry
                </h2>
                <p className="lead">
                  Register your interest below to receive a free productivity guide and our team will get in touch.
                </p>
              </div>
            </div>
          </div>
          <form 
            className="d-inline"
            onSubmit={(e) => {
              e.preventDefault();

              fetch(RequestContactEndpoint, {
                method: 'POST',
                body: JSON.stringify({
                  fullName,
                  workEmail,
                  contactNumber,
                  companyName,
                  companyType,
                  productInterest,
                  message,
                  subscribeMarketing
                })
              }).then(() => {
                setFullName('');
                setWorkEmail('');
                setContactNumber('');
                setCompanyName('');
                setCompanyType('');
                setProductInterest(defaultProduct);
                setMessage('');
                setSubscribeMarketing(false);
                toast.success('Message sent! Someone will be in touch shortly');
              }).catch(error => {
                console.error(error);
                toast.error('An error occurred, please try again');
              });
            }}
          >
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control border border-dark py-4"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Full Name*"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control border border-dark py-4"
                      value={workEmail}
                      onChange={(e) => setWorkEmail(e.target.value)}
                      placeholder="Work Email*"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control border border-dark py-4"
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                      placeholder="Contact Number*"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control border border-dark py-4"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      placeholder="Company Name*"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="h-100 d-flex flex-column">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control border border-dark py-4"
                      value={companyType}
                      onChange={(e) => setCompanyType(e.target.value)}
                      placeholder="Company Type*"
                    />
                  </div>
                  <div className="form-group">
                    <Dropdown isOpen={visibleDropdown} toggle={toggle}>
                      <DropdownToggle 
                        caret 
                        className="d-flex align-items-center bg-white border border-dark w-100 text-left rounded cursor-pointer px-3"
                        tag="div"
                        style={{ height: '50px' }}
                      >
                        <span>
                          { productInterest ?
                            <>{productInterest}</> :
                            <>Product of Interest</>
                          }
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        { AllProducts.map((product, i) => (
                          <DropdownItem
                            key={i}
                            onClick={() => setProductInterest(product)}
                          >{product}</DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="form-group flex-grow-1">
                    <textarea
                      className="form-control border border-dark h-100"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}                    
                      placeholder="Message*"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <div className="w-100 d-flex justify-content-end">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="subscribeMarketingCheckbox"
                      checked={subscribeMarketing}
                      onChange={() => setSubscribeMarketing(!subscribeMarketing)}
                    />
                    <label 
                      htmlFor="subscribeMarketingCheckbox"
                      className="form-check-label"
                    >
                      Yes, I want to get emails from Up-Family about products, promotions, events, and featured content at the email address above.
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-dark"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
