import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import CheckUpBanner from '@components/checkUpBanner';
import ContactSection from '@components/contactSection';
import ProductFeatures from '@components/productFeatures';

import '@styles/styles.scss';

export const CheckUpPage = ({ data }) => {
  const ctf = data.contentfulCheckUpPage;
  const { headline, subtitle } = ctf;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);

  const featuresTitle = ctf.valuePropositionTitle;
  const featuresSubtitle = ctf.valuePropositionSubtitle.valuePropositionSubtitle;
  const features = ctf.features;

  return (
    <Layout>
      <SEO title="Check Up" />
      <CheckUpBanner
        headline={headline}
        subtitle={subtitle.subtitle}
        assets={assets}
      />
      <ProductFeatures
        title={featuresTitle}
        subtitle={featuresSubtitle}
        features={features}
        assets={assets}
      />
      <ContactSection
        defaultProduct={'Check Up'}
      />
    </Layout>
  );
};

export default CheckUpPage;

export const pageQuery = graphql`
  query CheckUpQuery {
    contentfulCheckUpPage {
      headline
      subtitle {
        subtitle
      }
      features {
        featureContent
        featureTitle
        imageTitle
      }
      valuePropositionTitle
      valuePropositionSubtitle {
        valuePropositionSubtitle
      }
    }
    allContentfulAsset {
      edges {
        node {
          file {
            contentType
            url
            fileName
          }
          title
        }
      }
    }
  }
`;
